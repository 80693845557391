<template>
    <v-card
    class="mx-auto mt-3"
    max-width="300"
    tile
  >
    <v-list flat>
      <v-subheader>Front-End</v-subheader>
      <v-list-item-group
        v-model="frontEndSkill"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in frontEndSkills"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-subheader>Back-End</v-subheader>
      <v-list-item-group
        v-model="backEndSkill"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in backEndSkills"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      frontEndSkill: 1,
      backEndSkill: 1,
      frontEndSkills: [
        { text: 'Html', icon: 'mdi-clock' },
        { text: 'Css', icon: 'mdi-account' },
        { text: 'Javascript', icon: 'mdi-flag' },
        { text: 'Typescript', icon: 'mdi-flag' },
        { text: 'Angular 2+', icon: 'mdi-flag' },
        { text: 'VueJs', icon: 'mdi-flag' },
        { text: 'Kendo UI', icon: 'mdi-flag' }
      ],
      backEndSkills: [
        { text: 'Java', icon: 'mdi-clock' }
      ],
    }),
  }
</script>