
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Navbar extends Vue {
  changeLocale(param: any) {
    this.$i18n.locale = param;
  }

  menuRoutes = [
    {
      //permission: 'VIEW_DEVICES',
      label: "APP.COMPONENTS.HOMEPAGE.TITLE",
      route: "Home",
      icon: "router",
      image: "",
    },
    {
      //permission: 'VIEW_KITS',
      label: "APP.COMPONENTS.ABOUT.TITLE",
      route: "about",
      icon: "devices_other",
      image: "",
    },
    {
      //permission: 'VIEW_KITS',
      label: "APP.COMPONENTS.STUDIO.TITLE",
      route: "skills",
      icon: "devices_other",
      image: "",
    },
    {
      //permission: 'VIEW_VEHICLE_MODELS',
      label: "APP.COMPONENTS.PHOTOS.TITLE",
      route: "client",
      icon: "directions_car",
      image: "",
    },
    {
      //permission: 'VIEW_VEHICLE_MODELS',
      label: "APP.COMPONENTS.CONTACT.TITLE",
      route: "contact",
      icon: "directions_car",
      image: "",
    },
  ];
}
