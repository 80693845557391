/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from "vuetify";
import VueI18n from 'vue-i18n'
import "./assets/css/style.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(VueI18n)

Vue.config.productionTip = false;
Vue.use(Vuetify)

new Vue({
  router,
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#333333',
        },
      },
    },
  }),
  render: (h) => h(App)
}).$mount('#app');
