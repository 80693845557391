<template>
  <div id="app">
    <Navbar></Navbar>
    <Home></Home>
    <About></About>
    <Skills></Skills>
    <!-- <Projects></Projects>
    <Client></Client> -->
    <Contact></Contact>
    <a href="#" id="scroll-up">
        <i class="fa fa-home" aria-hidden="true"></i>
    </a>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue'
import Home from '@/views/HomeView.vue'
import About from '@/views/AboutView.vue'
import Skills from '@/views/SkillsView.vue'
import Projects from '@/views/ProjectsView.vue'
import Client from '@/views/ClientView.vue'
import Contact from '@/views/ContactsView.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  components: {
    Navbar,
    Home,
    About,
    Skills,
    Projects,
    Client,
    Contact,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #34495e;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#scroll-up{
    position: absolute;
    right: 3;
    bottom: 50;
    width: 0px;
    height: 50px;   
    opacity: .8;
    padding: 5px 0.3rem;
    border-radius: 0.4rem;
    color: #5A9885;
    background-color: 34495e;
}
</style>
