<template>
  <section id="skillspage" class="skills">
    <h2>Skills</h2>
    <div class="row">
      <div
        class="
          col-lg-12 col-md-12 col-sm-12
          d-flex
          justify-content-center
          align-items-center
          text-center
        "
      >
        <div id="skills-history">
          Percorso Formativo
          <SkillsHistory></SkillsHistory>
        </div>
      </div>
    </div>
    <br>
    <div class="row mt-5">
      <div
        class="
          col-lg-12 col-md-12 col-sm-12
          d-flex
          justify-content-center
          align-items-center
          text-center
        "
      >
        <div id="skills-list">
            Lista Skills
            <SkillsList></SkillsList>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SkillsHistory from '@/components/skills-history/SkillsHistory.vue'
import SkillsList from '@/components/skills-list/SkillsList.vue'

export default {
  components: {
    SkillsHistory,
    SkillsList
  }
}
</script>
<style>
</style>
